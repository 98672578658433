/* EditorNote.css */
/* Rimuove bordi e ombre dai principali componenti di SunEditor */


/* Se desideri rimuovere eventuali bordi interni agli elementi, puoi usare un selettore globale */
.sun-editor * {
  border: none !important;
  box-shadow: none !important;
}

/* Puoi aggiungere ulteriori regole per personalizzare lo stile in base alle tue esigenze */
