body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Charm:wght@400;700&family=Cinzel+Decorative&family=Lora:ital,wght@0,400..700;1,400..700&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Playwrite+GB+S:ital,wght@0,100..400;1,100..400&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');

.ant-table-filter-dropdown .ant-dropdown-menu,
.ant-table-filter-dropdown .ant-dropdown-menu-item {
  background-color: #f0f0f0 !important;
  color: #050505 !important;
}

.ant-table-filter-dropdown .ant-dropdown-menu li,
.ant-table-filter-dropdown .ant-dropdown-menu li * {
  color: #050505 !important;
}

.custom-input-number .ant-input-number-input {
  color: black !important;
}